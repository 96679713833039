<template>
  <Avatar
    v-if="image && showImage"
    :image="image"
    class="rounded-full bg-transparent"
    :class="avatarClasses"
    :style="avatarStyles"
    shape="circle"
    alt="user image"
    @error="showImage = false;" />
  <Avatar
    v-else-if="!image || !showImage"
    :icon="icon"
    :label="initials"
    class="rounded-full"
    :class="avatarClasses"
    :style="avatarStyles"
    shape="circle" />
</template>

<script lang="js">
import {
  defineComponent
} from "vue";

import Avatar from "primevue/avatar";

export default defineComponent({
  name: "ImageAvatar",
  components: {
    Avatar
  },
  props: {
    image: {
      type: String,
      default: null
    },
    initials: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    marginLeft: {
      type: String,
      default: null
    },
    marginRight: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "80"
    },
    fontSize: {
      type: String,
      default: "32"
    },
    styles: {
      type: Object,
      default: null
    },
    classes: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      imageError: false,
      showImage: true
    };
  },
  computed: {
    avatarStyles() {
      return {
        height: `${this.size}px`,
        "min-width": `${this.size}px`,
        "width": `${this.size}px`,
        "font-size": `${this.fontSize}px`,
        "margin-left": `${this.marginLeft}px`,
        "margin-right": `${this.marginRight}px`,
        "color": "white",
        ...this.styles
      };
    },
    avatarClasses() {
      return this.image && this.image.length > 0 && !this.imageError ? this.classes : "image-avatar".concat(" ", this.classes);
    }
  }
});
</script>

<style scoped lang="scss">
.image-avatar {
  @apply flex items-center justify-center overflow-hidden text-white rounded-full;
  color: white !important;
  height: 80px;
  min-width: 80px;
  border-radius: 9999px;
  background-color: #36334D;
  font-size: 32px;
}
</style>